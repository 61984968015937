import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import ServiceSection from "~sections/LandingPages/Connections/Service"
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import ContentThree from '~sections/project/ContentThree'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Outreach/Hero/HeroSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Outreach() {
  return (
    <>
    <Helmet>
    <title>Automate Your LinkedIn Outreach for only $49!</title>
    <meta name="description" content="For only $49 each month, you can reach 10x the number of leads. We-Connect offers advanced lead generation for LinkedIn – optimizing the way you prospect."/>
    <meta property="og:title" content="We-Connect | Automate Your LinkedIn Outreach for only $49!"/>
    <meta property="og:description" content="For only $49 each month, you can reach 10x the number of leads. We-Connect offers advanced lead generation for LinkedIn – optimizing the way you prospect."/>
    <meta property="og:image" content="https://we-connect.io/we-connect_automate-congratulation-messages-page.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/automate-your-linkedin-outreach"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="We-Connect | Automate Your LinkedIn Outreach for only $49!"/>
    <meta property="twitter:description" content="For only $49 each month, you can reach 10x the number of leads. We-Connect offers advanced lead generation for LinkedIn – optimizing the way you prospect."/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect_automate-congratulation-messages-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <ServiceSection/>
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
